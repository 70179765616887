



















































































































































































































































































































































































































































































































import Component, { mixins } from 'vue-class-component';

import WidgetMixins from '../../../mixins/WidgetMixins';
import DialogMixins from '../../../mixins/DialogMixins';
import { Getter, namespace } from 'vuex-class';
import {
  IActionDialog,
  IAPIKeys,
  IContact,
  IPaginate,
  IShareCredit,
  ITransferCredit,
  IUser,
} from '@/types/types';
import { loadView, loadWidget } from '@/utils/helpers';
import { Watch } from 'vue-property-decorator';
import { debounce } from 'lodash';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { email, numeric, required, max, min } from 'vee-validate/dist/rules';
import EmptyPage from '@/components/pages/EmptyPage.vue';

extend('required', {
  ...required,
  message: 'Field is required',
});
extend('email', {
  ...email,
  message: 'Field must be an email address',
});
extend('numeric', {
  ...numeric,
  message: 'Field must contain only numbers',
});
extend('max', {
  ...max,
  message: (field: any, params: any) => {
    return `${field} cannot be more than ${params.length} characters`;
  },
});

extend('min', {
  ...min,
  message: (field: any, params: any) => {
    return `${field} cannot be less than ${params.length} characters`;
  },
});

const contactModule = namespace('contact');
const usersModule = namespace('users');
const authModule = namespace('auth');
const apiKeyModule = namespace('apiKeys');

@Component({
  name: 'Index',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    AddSubAccountDialog: loadView('pages/settings/dialogs/AddSubAccountDialog'),
    ShareCreditDialog: loadView('pages/settings/dialogs/ShareCreditDialog'),
    GenerateAPIKeyDialog: loadView(
      'pages/settings/dialogs/GenerateAPIKeyDialog'
    ),
    DeleteDialog: loadWidget('widgets/DeleteDialog'),
    ValidationProvider,
    ValidationObserver,
  },
  filters: {},
})
export default class Index extends mixins(WidgetMixins, DialogMixins) {
  @Getter('getCreditBalance') creditBalance!: number;
  @Getter('getResetFormState') resetFormState!: boolean;
  @authModule.Getter('getUserDetails') user!: IUser;
  @usersModule.Getter('getPagination') paginate!: Pick<
    IPaginate,
    'page' | 'total' | 'itemsPerPage'
  > & { limit: number };

  @contactModule.Getter('getAllContacts') contacts!: ReadonlyArray<IContact>;
  @usersModule.Getter('getAllSubAccounts') subAccounts!: ReadonlyArray<IUser>;
  @apiKeyModule.Getter('getAPIKeys') apiKeyDetails!: IAPIKeys;
  @usersModule.Getter('getCustomerDetails') customerDetails!: IUser & {
    credit: number;
    creditReadonly: number;
  };

  subAccountsFormatted: ReadonlyArray<IUser> = [
    {
      id: '',
      username: '',
      name: '',
      phone: '',
      email: '',
    },
  ];

  transfer: ITransferCredit = {
    credit: 0,
    creditReadonly: 0,
    amountToTransfer: 0,
    calculatedAmount: 0,
    receipientAccountId: '',
    senderAccountId: '',
  };

  options: IPaginate = {
    itemsPerPage: 30,
    page: 1,
    total: 0,
  };
  sort: string | null = 'name,ASC';

  search = '';

  isSearching = false;

  details: Pick<
    IUser & {
      credit: number;
      creditReadonly: number;
      usernameReadonly: string | undefined;
      emailReadonly: string | null;
    },
    | 'id'
    | 'phone'
    | 'name'
    | 'username'
    | 'photo'
    | 'isActive'
    | 'email'
    | 'credit'
    | 'creditReadonly'
    | 'usernameReadonly'
    | 'emailReadonly'
  > = {
    id: '',
    username: '',
    name: '',
    phone: '',
    email: '',
    credit: 0,
    creditReadonly: 0,
    usernameReadonly: '',
    emailReadonly: '',
  };

  editable = false;

  actionDialog: Partial<IActionDialog> = {
    actionMessage: '',
    actionText: '',
    actionImage: '',
  };

  action = '';
  breadcrumbs = [{ text: 'Sub Account', href: '/sub-accounts' }];
  headers: Array<{
    text: string;
    value: string;
    align?: string;
    sortable?: boolean;
  }> = [
    {
      text: '',
      value: 'avatar',
      sortable: false,
    },

    {
      text: 'Name',
      value: 'name',
    },
    {
      text: 'Email',
      value: 'email',
    },
    {
      text: 'Phone',
      value: 'phone',
      sortable: false,
    },
    {
      text: 'Total Credit',
      value: 'creditBalance',
      sortable: false,
    },
    {
      text: 'Acc. Type',
      value: 'accountType',
      sortable: false,
    },
    {
      text: 'Acc. Status',
      value: 'isActive',
      sortable: false,
    },
    {
      text: 'Actions',
      align: 'center',
      value: 'actions',
      sortable: false,
    },
  ];

  mainAccountUser: Pick<IUser, 'id' | 'name' | 'email'> = {
    id: '',
    name: '',
    email: '',
  };

  searchText(term: string): void {
    this.$store.dispatch('users/list', {
      query: `?page=${this.paginate.page}&size=${
        this.paginate.itemsPerPage
      }&searchTerm=${term}${this.sort ? '&sort=' + this.sort : ''}`,
    });
  }

  handleOnSearchTextChange = debounce(this.searchText, 1000);

  @Watch('search')
  onSearchTermChange(term: string): void {
    this.handleOnSearchTextChange(term);
  }

  @Watch('subAccounts')
  onSubAccountPayloadChange(payload: ReadonlyArray<IUser>): void {
    this.subAccountsFormatted = [this.mainAccountUser, ...payload].filter(
      ({ id }) => Number(id) !== Number(this.$route.params.id)
    );
  }

  @Watch('resetFormState')
  onResetFormStateChange(payload: boolean) {
    if (payload) {
      this.transfer.amountToTransfer = null;
    }
  }

  @Watch('transfer.amountToTransfer')
  onAmountToTransferChange(value: number) {
    this.transfer.calculatedAmount = this.transfer.credit - value;
  }

  @Watch('customerDetails')
  onCustomerDetailsChange(
    payload: IUser & {
      credit: number;
      creditReadonly: number;
      calculatedAmount: number;
    }
  ) {
    this.transfer = {
      creditReadonly: payload.credit,
      credit: payload.credit,
      amountToTransfer: null,
      calculatedAmount: 0,
      senderAccountId: this.$route.params.id,
      receipientAccountId: '',
    };
    this.details = {
      ...payload,
      creditReadonly: payload.credit,
      usernameReadonly: payload.username,
      emailReadonly: payload.email,
    };
  }

  @Watch('options')
  onOptionsChange(payload: IPaginate): void {
    if (
      payload.sortBy &&
      payload.sortBy.length > 0 &&
      payload.sortDesc &&
      payload.sortDesc.length > 0
    ) {
      this.sort = `${payload.sortBy[0]},${
        payload.sortDesc[0] ? 'DESC' : 'ASC'
      }`;
    }

    this.$store.dispatch('users/list', {
      query: `?page=${payload.page}&size=${payload.itemsPerPage}${
        this.sort ? '&sort=' + this.sort : ''
      }`,
    });
  }

  updateCustomerDetails(): void {
    this.$store.dispatch('users/updateCustomerProfile', {
      id: this.details.id,
      email: this.details.email,
      phone: this.details.phone,
      name: this.details.name,
      username: this.details.username,
      usernameReadonly: this.details.usernameReadonly,
      emailReadonly: this.details.emailReadonly,
      acceptPrivacyPolicy: true,
    });
  }

  transferCredit(): void {
    this.$store.dispatch('users/transferCredit', {
      ...this.transfer,
      amountToTransfer: Number(this.transfer.amountToTransfer),
    });
  }

  sendResetLink(): void {
    this.$store.dispatch('users/sendPasswordResetLink', {
      email: this.customerDetails?.email,
    });
  }

  // openDeleteDialog(
  //   item: IUser & { isActive: boolean; credit: number; creditReadonly: number, },
  //   action: string
  // ): void {
  //   this.open('delete');
  //   this.details = item;
  //   this.action = action;
  //   if (action === 'activate') {
  //     this.actionDialog = {
  //       actionText: item.isActive ? 'Deactivate' : 'Activate',
  //       actionImage: require('@/assets/images/settings.png'),
  //       actionMessage:
  //         'Once you perform this operation, the admin of this sub account cannot use it',
  //     };
  //   } else {
  //     this.actionDialog = {
  //       actionMessage:
  //         'Once you delete this sub account, this operation cannot be undone.',
  //     };
  //   }
  // }

  deleteSubAccount(): void {
    if (!this.details?.id) {
      this.$store.dispatch(
        'snackBarMessage',
        `Unable to communicate with our backend service to perform this action, try again later`,
        { root: true }
      );
      this.$store.dispatch('snackBarVisibility', true, { root: true });
      return;
    }
    if (this.action === 'activate') {
      this.$store.dispatch('users/changeSubAccountStatus', {
        id: this.details?.id,
        isActive: !this.details?.isActive,
      });
    } else {
      this.$store.dispatch('users/deleteSubAccount', { id: this.details?.id });
    }
  }

  copyContact(text: string): void {
    this.$copyText(text).then(
      () => {
        this.$store.dispatch('snackBarMessage', `Copied`, { root: true });
        this.$store.dispatch('snackBarVisibility', true, { root: true });
      },
      () => {
        this.$store.dispatch('snackBarMessage', `Unable to copy contact`, {
          root: true,
        });
        this.$store.dispatch('snackBarVisibility', true, { root: true });
      }
    );
  }

  apiKeyDialog(id: string | number, action: string): void {
    if (action === 'open') {
      this.$router.push({
        query: {
          accId: id as string,
        },
      });
    } else {
      this.close({ idx: 'edit', state: false });
      this.$router.push({
        query: {
          accId: undefined,
        },
      });
    }
  }

  generateAPIKey(): void {
    this.$store.dispatch('apiKeys/generateKey', {
      name: 'APP',
      customerId: this.apiKeyDetails?.userId ?? this.$route.query?.accId,
      key: 'edit',
    });
  }

  revokeAPIKey(id: number): void {
    this.$store.dispatch('apiKeys/revokeKeys', {
      id,
      customerId: this.apiKeyDetails?.userId ?? this.$route.query?.accId,
      key: 'edit',
    });
  }

  copyCredentials(payload: { credential: string; field?: string }): void {
    navigator.clipboard.writeText(payload.credential).then(
      (message) => {
        console.log(message);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  created(): void {
    if (this.user) {
      this.mainAccountUser = {
        id: this.user?.id,
        name: `MAIN ACCOUNT`,
        email: this.user?.email,
      };
    }
    const query = `?page=1&size=15${this.sort ? '&sort=' + this.sort : ''}`;
    this.$store.dispatch('users/list', {
      query,
    });
    this.$store.dispatch('checkCreditBalance');
    if (this.$route.params.id) {
      this.$store.dispatch('users/customerDetails', {
        accountId: this.$route.params.id,
      });
    }
    if (this.$route.query?.accId) {
      this.open(
        'edit',
        this.$route.query.accId as string,
        'apiKeys/apiKeyByCustomerID'
      );
    }
  }
}
