var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-shrink-1 flex-wrap",attrs:{"id":"institution-pane"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-wrap flex-shrink-1"},[_c('div',{staticClass:"d-flex flex-column mid-container-full"},[_c('div',{staticClass:"dash-side-container dash-side-container-topNavbar"},[_c('ToolBar',{attrs:{"title":_vm.$route.meta.pageTitle,"balanceLoading":_vm.isBalanceLoading,"creditBalance":_vm.creditBalance},on:{"topUpDialog":function($event){return _vm.open('payment')}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"loading":_vm.pageLoading,"small":"","color":"accent","icon":"","fab":""},on:{"click":function($event){return _vm.$store.dispatch('users/list', {
                      query: ("?page=" + (_vm.paginate.page) + "&size=" + (_vm.paginate.itemsPerPage) + "&sort=" + _vm.sort),
                    })}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("cloud_done")])])]}}])},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Get Latest Changes")])])]},proxy:true}])})],1),_c('v-row',{staticClass:"container-view",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.internetState)?_c('div',{staticClass:"d-flex flex-row flex-wrap",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1 flex-wrap"},[_c('div',{staticClass:"container-fluid d-flex flex-column flex-grow-1 flex-shrink-1 mt-5 animate__animated animate__fadeIn",staticStyle:{"padding":"0px !important"}},[_c('div',{staticClass:"d-flex animate__animated animate__fadeIn ml-5 flex-column flex-grow-1 flex-wrap flex-shrink-1"},[(_vm.$route.params.id)?_c('v-breadcrumbs',{staticClass:"pl-2 primary--text pb-0",attrs:{"items":_vm.breadcrumbs.concat( [{ text: _vm.customerDetails.name, disabled: true }] )},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                    var item = ref.item;
return [_c('v-breadcrumbs-item',{attrs:{"href":item.href,"disabled":item.disabled}},[_c('span',{staticClass:"ft font-weight-medium text-md"},[_vm._v(_vm._s(item.text))])])]}}],null,false,2661474978)}):_vm._e(),_c('p',{staticClass:"ft font-weight-medium px-2 pt-1"},[_vm._v(" View/Update the sub account details ")])],1),_c('v-container',{attrs:{"fluid":""}},[(_vm.pageLoading)?_c('ProgressBar',{staticClass:"mt-10",attrs:{"size":30,"width":5,"loading":_vm.pageLoading}}):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"8","sm":"12","lg":"8","xl":"8"}},[_c('div',{staticClass:"white d-flex flex-column vx-shadow flex-fill flex-grow-1 py-3 px-3 pb-3 rounded-md"},[_c('div',{staticClass:"d-flex flex-column flex-fill flex-grow-1",staticStyle:{"align-items":"center"}},[_c('v-avatar',{staticClass:"mt-3 mb-3",attrs:{"size":"100","color":"primary"}},[_c('span',{staticClass:"white--text text-lg ft font-weight-medium"},[_vm._v(_vm._s(_vm.customerDetails.name.charAt(0)))])]),_c('h2',{staticClass:"ft font-weight-medium"}),_c('h3',{staticClass:"ft font-weight-medium text-uppercase text-sm text-center"},[_vm._v(" "+_vm._s(_vm.customerDetails.name)+" ")]),_c('v-chip',{staticClass:"mt-1 ft font-weight-medium",attrs:{"label":"","small":"","color":"accent"}},[_vm._v(_vm._s(_vm.customerDetails.email))]),_c('v-btn',{staticClass:"mr-2 mt-2",attrs:{"text":"","rounded":""}},[_c('span',{staticClass:"ft font-weight-bold font-size-sm"},[_vm._v("Credits "+_vm._s(_vm.transfer.credit - (_vm.transfer.amountToTransfer ? _vm.transfer.amountToTransfer : 0))+" ")])])],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateCustomerDetails)}}},[_c('div',{staticClass:"d-flex flex-column flex-fill flex-grow-1 mt-5 grey lighten-4 rounded-md"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 align-end justify-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var props = ref.props;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"icon":"","color":"primary","small":""},on:{"click":function($event){_vm.editable = !_vm.editable}}},'v-btn',props,false),on),[_c('i',{staticClass:"material-icons-outlined text-sm"},[_vm._v(_vm._s(!_vm.editable ? 'edit' : 'close'))])])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v(_vm._s(!_vm.editable ? 'Edit Details' : 'Cancel Editing'))])])],1),_c('div',{staticClass:"d-flex flex-row flex-wrap flex-full flex-grow-1 ma-3"},[_c('ValidationProvider',{staticClass:"d-flex flex-column flex-full flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('i',{staticClass:"material-icons-outlined mr-4 mt-3",class:errors.length > 0
                                          ? 'error--text'
                                          : 'black--text'},[_vm._v("manage_accounts")]),_c('v-text-field',{staticClass:"ft font-weight-medium text-sm",attrs:{"error-messages":errors[0],"autofocus":"","outlined":"","disabled":!_vm.editable,"hint":"Provide an account name here","label":"Account Name","persistent-hint":true},model:{value:(_vm.details.name),callback:function ($$v) {_vm.$set(_vm.details, "name", $$v)},expression:"details.name"}})],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-column column-wrap flex-grow-1",attrs:{"tag":"div","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                          var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-full"},[_c('i',{staticClass:"material-icons-outlined mr-4 mt-3",class:errors.length > 0
                                          ? 'error--text'
                                          : 'black--text'},[_vm._v("email")]),_c('v-text-field',{staticClass:"ft font-weight-medium text-sm",attrs:{"error-messages":errors[0],"disabled":!_vm.editable,"outlined":"","hint":"Provide an email address","label":"Email Address","persistent-hint":true},model:{value:(_vm.details.email),callback:function ($$v) {_vm.$set(_vm.details, "email", $$v)},expression:"details.email"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-wrap flex-grow-1 ma-3"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('i',{staticClass:"material-icons-outlined mr-4 mt-3",class:'black--text'},[_vm._v("manage_accounts")]),_c('v-text-field',{staticClass:"ft font-weight-medium text-sm",attrs:{"outlined":"","autofocus":"","disabled":!_vm.editable,"hint":"Provide a username here","label":"Username","persistent-hint":true},model:{value:(_vm.details.username),callback:function ($$v) {_vm.$set(_vm.details, "username", $$v)},expression:"details.username"}})],1)]),_c('ValidationProvider',{staticClass:"d-flex flex-row column-wrap flex-grow-1",attrs:{"tag":"div","rules":"required|numeric|min:9|max:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                          var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-full"},[_c('i',{staticClass:"material-icons-outlined mt-3",class:errors.length > 0
                                          ? 'error--text'
                                          : 'black--text'},[_vm._v("phone")]),_c('v-text-field',{staticClass:"ft font-weight-medium text-sm",attrs:{"outlined":"","error-messages":errors[0],"disabled":!_vm.editable,"hint":"Provide a phone number","label":"Phone Number","persistent-hint":true},model:{value:(_vm.details.phone),callback:function ($$v) {_vm.$set(_vm.details, "phone", $$v)},expression:"details.phone"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-end align-end mb-2 mr-3"},[_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.editable || _vm.isLoading,"loading":_vm.isLoading,"small":"","color":"primary"}},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Update Profile")])])],1)])])]}}],null,false,2223971091)})],1)]),_c('v-col',{attrs:{"md":"4","sm":"12","lg":"4","xl":"8"}},[_c('div',{staticClass:"white d-flex flex-column flex-fill flex-grow-1 py-3 px-3 rounded-md mx-1 mb-8"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                          var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.transferCredit)}}},[_c('div',{staticClass:"d-flex flex-column flex-fill flex-grow-1 mt-5 grey lighten-4 rounded-md"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 pt-3 align-start justify-right"},[_c('h3',{staticClass:"ft font-weight-medium text-sm text-uppercase px-4"},[_vm._v(" Transfer Credit ")])]),_c('div',{staticClass:"d-flex flex-row flex-wrap flex-grow-1 ma-3"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 flex-full",attrs:{"tag":"div","rules":"required|max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                          var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mr-3 mt-3",class:errors.length > 0
                                        ? 'error--text'
                                        : 'black--text'},[_vm._v("manage_accounts")]),_c('v-autocomplete',{staticClass:"ft font-weight-bold text-sm",attrs:{"error-messages":errors[0],"autofocus":"","items":_vm.subAccountsFormatted,"outlined":"","item-value":"id","item-text":"name","hint":"Select an account","label":"Account","persistent-hint":true},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                                        var item = ref.item;
return [_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v(_vm._s(item.name))])]}},{key:"no-data",fn:function(){return [_c('v-list-item',[_c('span',{staticClass:"ft font-weight-medium text-sm mr-3"},[_vm._v("No Account")])])]},proxy:true}],null,true),model:{value:(_vm.transfer.receipientAccountId),callback:function ($$v) {_vm.$set(_vm.transfer, "receipientAccountId", $$v)},expression:"transfer.receipientAccountId"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 flex-full column-wrap flex-grow-1",attrs:{"tag":"div","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-full"},[_c('i',{staticClass:"material-icons-outlined mr-3 mt-3",class:errors.length > 0
                                          ? 'error--text'
                                          : 'black--text'},[_vm._v("credit_card")]),_c('v-text-field',{staticClass:"ft font-weight-medium text-sm",class:[
                                        _vm.transfer.calculatedAmount < 0
                                          ? ' animate__animated  animate__shakeX'
                                          : '' ],attrs:{"outlined":"","error-messages":errors[0],"hint":"Total credit amount to transfer","label":"Enter Credit Amount","persistent-hint":true},model:{value:(_vm.transfer.amountToTransfer),callback:function ($$v) {_vm.$set(_vm.transfer, "amountToTransfer", $$v)},expression:"transfer.amountToTransfer"}})],1)]}}],null,true)}),_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-end align-end"},[_c('v-btn',{attrs:{"type":"submit","loading":_vm.isDialogLoading,"disabled":_vm.transfer.calculatedAmount < 0 ||
                                      _vm.isDialogLoading ||
                                      _vm.details.credit <= 0,"small":"","color":"primary"}},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Tranfer")])])],1)],1)])])]}}],null,false,3336030985)}),_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-fill grey lighten-4 mt-3 pb-3 rounded-md mb-4"},[_c('EmptyPage',{staticClass:"mt-5",attrs:{"title":"Password Reset","subTitle":"Send an email/sms for this account password reset","image":require('@/assets/images/padlock.png')},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mt-4",attrs:{"color":"primary","loading":_vm.inProgress,"disabled":_vm.inProgress,"depressed":""},on:{"click":_vm.sendResetLink}},[_c('span',{staticClass:"ft font-weight-medium text-capitalize text-sm"},[_vm._v("Send Reset Link")])])]},proxy:true}],null,false,3201334819)})],1)],1)])],1)],1)],1)])]):_c('InternetConnection',{scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{staticClass:"animate__animated animate__fadeIn",attrs:{"rounded":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.$store.dispatch('users/customerDetails', {
                    accountId: _vm.$route.params.id,
                  })}}},[_c('span',{staticClass:"ft font-weight-medium text-sm text-capitalize"},[_vm._v("Try Again")])])]},proxy:true}])})],1)],1)],1)]),_c('GenerateAPIKeyDialog',{attrs:{"state":_vm.dialog('edit'),"loading":_vm.isLoading,"dialogLoading":_vm.isDialogLoading,"apiKeyDetails":_vm.apiKeyDetails},on:{"generateKey":_vm.generateAPIKey,"revokeKey":_vm.revokeAPIKey,"actions":function($event){return _vm.apiKeyDialog('', 'close')},"copy":_vm.copyCredentials}}),_c('DeleteDialog',{attrs:{"state":_vm.dialog('delete'),"image":_vm.actionDialog.actionImage,"actionText":_vm.actionDialog.actionText,"message":_vm.actionDialog.actionMessage,"loading":_vm.isLoading},on:{"actions":_vm.close,"delete":_vm.deleteSubAccount}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }